




import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import InstructorForm from "./InstructorForm.vue";
import { EDIT } from "@/constants/FormType";
import { mixins } from "vue-class-component";
import KeepAliveMixin from "@/mixins/KeepAliveMixin";
import CrudService from "@/services/CrudService";
import { AxiosError, AxiosResponse } from "axios";
import { IInstructor } from "@/interfaces/IInstructor";

const InstructorModule = namespace("instructor");

const crudService = new CrudService();

const URL_INSTRUCTOR = "instructor";

@Component({
  components: {
    InstructorForm,
  },
})
export default class InstructorEdit extends mixins(KeepAliveMixin) {
  public name = "InstructorEdit";

  public instructor: IInstructor | null = null;

  @InstructorModule.Action("update")
  public update: any;

  @InstructorModule.Getter("getIsLoading")
  public getIsLoading: any;

  @InstructorModule.Getter("getSuccess")
  public success: any;

  @InstructorModule.Action("workHoursUpdate")
  public workHoursUpdateAction: any;

  @InstructorModule.Action("getError")
  public error: any;

  @InstructorModule.Action("userAvatarUpload")
  protected userAvatarUpload: any;

  @InstructorModule.Mutation("SET_ERROR")
  public SET_ERROR: any;

  @InstructorModule.Mutation("SET_SUCCESS")
  public SET_SUCCESS: any;

  public async onSave(data: any): Promise<void> {
    const id = this.$route.params.id;

    await this.update({
      id: id,
      data: { id: id, ...data.data },
      resource: "instructor",
      descriptionField: "email",
    });

    await this.workHoursUpdateAction({
      id: id,
      dayWorkHours: data.dayWorkHours,
    });

    if (data.fileData.file) {
      const formData = new FormData();
      formData.append("file", data.fileData.file);
      formData.append("userId", id);
      this.userAvatarUpload(formData).then(() => {
        this.$root.$emit("avatar-updated");
      });
    }

    if (this.success) {
      await this.$router.push({ name: "Instructors" });
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  protected onUrlChange(): void {
    if (!this.activeKeepAlive) {
      return;
    }
    this.fetchInstructor(Number.parseInt(this.$route.params.id));
  }

  public get typeEdit(): string {
    return EDIT;
  }

  protected async fetchInstructor(instructorId: number): Promise<void> {
    this.SET_ERROR(null);
    this.SET_SUCCESS(false);
    return await crudService
      .findOne({
        resource: URL_INSTRUCTOR,
        descriptionField: "name",
        id: instructorId,
      })
      .then((response: AxiosResponse) => {
        this.instructor = response.data;
        this.SET_SUCCESS(true);
      })
      .catch((error: AxiosError) => {
        this.SET_ERROR(error.response?.data);
      });
  }
}
