







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TestButton extends Vue {
  public name = "TestButton";

  @Prop({
    type: String,
    default: function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$t("button.test");
    },
  })
  public label!: string;
}
