import { Component, Vue } from "vue-property-decorator";

@Component
export default class KeepAliveMixin extends Vue {
  public activeKeepAlive = true;

  protected activated(): void {
    this.activeKeepAlive = true;
  }

  protected deactivated(): void {
    this.activeKeepAlive = false;
  }
}
