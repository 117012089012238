




import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FscPlaces extends Vue {
  public name = "FscPlaces";

  @Prop()
  public value: any;
}
