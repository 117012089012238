




import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ButtonMixin from "@/mixins/ButtonMixin";
import i18n from "@/i18n";
import { v4 as uuidv4 } from "uuid";
import ActionButton from "@/components/Button/ActionButton.vue";
@Component({
  components: { ActionButton },
})
export default class CreateButton extends mixins(ButtonMixin) {
  public name = "CreateButton";

  @Prop({ default: () => uuidv4() })
  public id!: string;

  @Prop({ default: () => i18n.t("general.button_new_branch") })
  public tooltipLabel!: string;
}
