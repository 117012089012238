import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ButtonMixin extends Vue {
  @Prop({ default: null })
  public size!: string | null;

  @Prop({ default: () => null })
  public tooltipLabel!: string;

  @Prop({ default: () => "", type: String })
  public label!: string;

  @Prop({ default: () => "", type: String })
  public iconClass!: string;

  @Prop({ default: () => "fat", type: String })
  public iconPrefix!: string;

  @Prop({ default: () => "", type: String })
  public labelClass!: string;

  @Prop({ default: () => "top", type: String, required: false })
  public tooltipPosition!: string;

  public get className(): string | null {
    return this.size === "sm" ? "action-btn-small" : null;
  }

  public get iconSize(): string | null {
    return this.size;
  }
}
