import { Component, Vue } from "vue-property-decorator";
import axios from "@/utils/axios";
import { IEmailConfig } from "@/interfaces/IEmailConfig";

const URL = "/email-config";

@Component
export default class EmailConfigRequestMixin extends Vue {
  public emailsConfigByUser: Array<IEmailConfig> = [];
  public emailsConfigByUserLoading = false;
  public emailsConfigByUserSuccess = false;
  public emailsConfigByUserError: Record<any, any> | null = null;

  public async emailsConfigByUserId(userId: number): Promise<void> {
    this.emailsConfigByUserLoading = true;
    this.emailsConfigByUserLoading = false;
    this.emailsConfigByUserError = null;
    return await axios
      .get(`${URL}/user/${userId}/email-configs`)
      .then(({ data }) => {
        this.emailsConfigByUser = data;
        this.emailsConfigByUserSuccess = true;
      })
      .catch(({ response }) => {
        this.emailsConfigByUserError = response.data;
      })
      .finally(() => {
        this.emailsConfigByUserLoading = false;
      });
  }
}
